/* eslint-disable */
import Swiper from 'swiper/bundle'

(function ($) {
    var AjaxDocs = function (item) {
        this.$container = item.find('.js_docs-container');
        this.$term = item.find('.js_change-term');
        this.$pagination = item.find('.js_list-pagination');
        this.$list = item.find('.js_list-docs');
        this.$popinList = item.find('.js_popin-doc-container');
        this.params = [];
        this.params.ajaxUrl = ajaxObject.ajaxurl;
        this.params.ajaxActionChange = 'change';
        this.params.ajaxActionLoadmore = 'loadmore';
        this.params.ajaxMethod = 'documents';

        this.init();
    };

    AjaxDocs.prototype.init = function () {
        var self = this;

        $(document).on('click', '.js_change-term', function (e) {
            e.stopImmediatePropagation();
            $('.js_change-term').removeClass('active');
            $(this).addClass('active');
            self.change($(this).attr('data-value'));
        })
        // self.$term.on('change', function (e) {
        //     self.change($(self.$term).val());
        // });

        $(document).on('click', '.js_load-more', function (e) {
            e.stopImmediatePropagation();
            self.loadmore($('.js_load-more').attr('data-term'), $('.js_load-more').attr('data-page'));
        })
    };



    AjaxDocs.prototype.openPopin = function (popin, slide) {
        $('.popin-wrapper[data-popin="' + popin + '"]').show(0).addClass('active');
        $('html').addClass('disable-scroll');
        if (slide) {
            sliderImage[$('.popin-wrapper[data-popin="' + popin + '"]').find('.slider').data('index')].slideTo(slide);
        }
    }

    AjaxDocs.prototype.closePopin = function () {
        $('.popin-wrapper').removeClass('active');
        $('html').removeClass('disable-scroll');
        setTimeout(function () {
            $('.popin-wrapper').hide(0);
        }, 600);
    }

    AjaxDocs.prototype.reloadPopin = function () {
        $(document).on('click', '.js_open-popin', function (e) {
            self.openPopin($(this).data('popin'), $(this).data('slide'));
        });


        $(document).on('click', '.js_close-popin', function (e) {
            self.closePopin();
        });
        $(document).on('click', '.popin-wrapper', function (e) {
            if (e.target !== this) {
                return;
            }
            self.closePopin();
        });
    }
    AjaxDocs.prototype.reloadSwipper = function () {
        $('.js_documents-slider').each(function () {
            let elt = this;
            if ($(elt).find('.sub-item').length > 1) {
                new Swiper(elt, {
                    loop: true,
                    speed: 750,
                    allowTouchMove: false,
                    navigation: {
                        nextEl: '.js_documents-slider-btn-next',
                        prevEl: '.js_documents-slider-btn-prev',
                    },
                    pagination: {
                        el: '.js_documents-slider-pagination',
                        type: 'bullets',
                        clickable: true,
                    },
                });
            }
        });
        $('.js_documents-popin-slider').each(function () {
            let elt = this;
            if ($(elt).find('.item').length > 1) {
                new Swiper(elt, {
                    loop: true,
                    speed: 750,
                    navigation: {
                        nextEl: '.js_documents-popin-slider-btn-next',
                        prevEl: '.js_documents-popin-slider-btn-prev',
                    },
                    pagination: {
                        el: '.js_documents-popin-slider-pagination',
                        type: 'bullets',
                        clickable: true,
                    },
                });
            }
        });
    }

    AjaxDocs.prototype.change = function (term) {
        var self = this;

        $.ajax({
            url: self.params.ajaxUrl,
            method: 'POST',
            data: {
                action: self.params.ajaxActionChange,
                method: self.params.ajaxMethod,
                term: term
            },
            dataType: 'json',
            success: function (response) {
                self.$popinList.empty();
                self.$popinList.append(response.data.popin)
                self.$list.empty();
                self.$list.append(response.data.html);
                self.$pagination.empty();
                self.$pagination.html(response.data.pagination);
                self.reloadSwipper();
                // self.reloadPopin();
            },
        }).fail(function (textStatus, errorThrown) {
            console.log(textStatus);
            console.log(errorThrown);
        });
    };


    AjaxDocs.prototype.loadmore = function (term, targetPage = 0) {
        var self = this;

        $.ajax({
            url: self.params.ajaxUrl,
            method: 'POST',
            data: {
                action: self.params.ajaxActionLoadmore,
                method: self.params.ajaxMethod,
                term: term,
                targetPage: targetPage,
            },
            dataType: 'json',
            success: function (response) {
                self.$popinList.append(response.data.popin)
                self.$list.append(response.data.html);
                self.$pagination.empty();
                self.$pagination.html(response.data.pagination);
                self.reloadSwipper();
                // self.reloadPopin();
            },
        }).fail(function (textStatus, errorThrown) {
            console.log(textStatus);
            console.log(errorThrown);
        });
    };
    $(document).ready(function () {

        if ($('.js_docs-container').length > 0) {
            new AjaxDocs($(this));
        }
    });
})(jQuery);

